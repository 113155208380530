import React from "react"

import { UsBlockImageText } from "../_components/us_block_image_text"

export const UsImageText = props => {
  return (
    <div className="us-image-text">
      <UsBlockImageText {...props} />
    </div>
  )
}
