import React from "react"

import { UsBlockHeader } from "../_components/us_block_header"
import { UsBlockImageText } from "../_components/us_block_image_text"

export const UsImageSlides = ({ number, title, description, items }) => {
  return (
    <div className="us-image-slides">
      <UsBlockHeader number={number} title={title} description={description} />
      <div className="us-image-slides__items">
        {items.map((item, index) => {
          item.orientation = index % 2 === 0 ? "left" : "right"
          return <UsBlockImageText key={`key-image-item-${index}`} {...item} />
        })}
      </div>
    </div>
  )
}
