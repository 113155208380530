import React from "react"

import { Picture } from "react-responsive-picture"

export const UsBanner = ({ image }) => {
  return (
    <div className="us-banner">
      <div className="us-banner__bg">
        <Picture
          className="us-banner__img"
          sources={[
            {
              srcSet: image.xs.url,
              media: "(max-width: 767px)",
            },
            {
              srcSet: image.sm.url,
              media: "(max-width: 1023px)",
            },
            {
              srcSet: image.md.url,
              media: "(max-width: 1280px)",
            },
            {
              srcSet: image.lg.url,
              media: "(max-width: 1366px)",
            },
            {
              srcSet: image.url,
              media: "(min-width: 1367px)",
            },
          ]}
        />
      </div>
    </div>
  )
}
