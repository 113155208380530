import React, { useRef, useState, useEffect } from "react"
import Loadable from "@loadable/component"

import { UsBlockHeader } from "../_components/us_block_header"
import SvgArrowReturn from "../../../components/svg/commons/arrow_return"

const Slider = Loadable(() => import("react-slick"))

export const UsSvgSlides = ({ type, number, title, description, items }) => {
  const [total, setTotal] = useState(0)
  const [width, setWidth] = useState(0)
  const [isVisibleBtns, setIsVisibleBtns] = useState(true)
  const slider = useRef(null)
  const sliderContainer = useRef(null)
  const prevBtn = useRef(null)
  const nextBtn = useRef(null)

  let interval = 0

  const settings = {
    arrows: false,
    dots: false,
    draggable: true,
    infinite: true,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipe: true,
    swipeToSlide: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1359,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1099,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 699,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const handleNextSlider = () => {
    slider.current.slickNext()
  }

  const handlePrevtSlider = () => {
    slider.current.slickPrev()
  }

  useEffect(() => {
    if (type === "card" && sliderContainer.current) {
      const handleWindowResize = () => {
        setWidth(window.innerWidth)
      }

      if (total === 0) {
        setTotal(items.length)
        return
      }

      if (width === 0) {
        handleWindowResize()
        return
      }

      clearTimeout(interval)
      interval = setTimeout(() => {
        const sliderSlick = sliderContainer.current ? sliderContainer.current.children[0] : null
        const sliderTrack = sliderContainer.current ? sliderContainer.current.children[0].children[0].children[0] : null
        setIsVisibleBtns(sliderSlick.offsetWidth > sliderTrack.offsetWidth - 20 ? false : true)
      }, 100)

      window.addEventListener("resize", handleWindowResize)
      return () => {
        clearTimeout(interval)
        window.removeEventListener("resize", handleWindowResize)
      }
    }
  }, [total, width])

  return (
    <div className={`us-svg-slides is-${type}`}>
      <UsBlockHeader number={number} title={title} description={description} />
      <div className="us-svg-slides__items">
        {type === "default" ? (
          <>
            {items.map((item, index) => {
              return <UsSvgSlideItem key={`key-image-item-${index}`} {...item} />
            })}
          </>
        ) : (
          <div className={`us-slider-container`} ref={sliderContainer}>
            <Slider ref={slider} {...settings}>
              {items.map((item, index) => {
                return <UsSvgSlideItem key={`key-image-item-${index}`} {...item} />
              })}
            </Slider>
            <button className={`us-slick-arrow us-slick-prev ${isVisibleBtns ? `is-visible` : ``}`} ref={prevBtn} onClick={handlePrevtSlider}>
              <div className="us-slick-arrow-content">
                <SvgArrowReturn />
              </div>
            </button>
            <button className={`us-slick-arrow us-slick-next ${isVisibleBtns ? `is-visible` : ``}`} ref={nextBtn} onClick={handleNextSlider}>
              <div className="us-slick-arrow-content">
                <SvgArrowReturn />
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

const UsSvgSlideItem = ({ image, title, description, theRef }) => {
  return (
    <div className="us-svg-slides__item-container" ref={theRef}>
      <div className="us-svg-slides__item">
        <div className="us-svg-slides__image">
          <img alt={title.text} src={image.url} />
        </div>
        <div className="us-svg-slides__content">
          <span className="us-svg-slides__item-title">{title.text}</span>
          <span className="us-svg-slides__item-description" dangerouslySetInnerHTML={{ __html: description.html }} />
        </div>
      </div>
    </div>
  )
}
