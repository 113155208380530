import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import { usePrismUs } from "./../hooks/use_prims_us"
import { UsBanner } from "../content/us/banner/us_banner"
import { UsBannerText } from "../content/us/banner/us_banner_text"
import { UsImageText } from "../content/us/image_text/us_image_text"
import { UsImageSlides } from "../content/us/slides/us_image_slides"
import { UsSvgSlides } from "../content/us/slides/us_svg_slides"
import { UsStorytelling } from "../content/us/storytelling/us_storytelling"
import "../styles/pages/us/us.scss"

const About = () => {
  const usQuery = usePrismUs()
  let metasData

  usQuery.data.body.forEach(item => {
    if (item.__typename === "PrismicUsPageBodyMetas") {
      metasData = item.primary
    }
  })

  return (
    <Layout>
      {/* <Head title={metasData.title.text} pathname={`/${usQuery.uid}`} description={metasData.description.text} image={metasData.image_share.url} /> */}
      {usQuery.data.body.map((block, index) => {
        if (block.__typename === "PrismicUsPageBodyBanner") {
          return <UsBanner key={`key-block-${index}`} {...block.primary} />
        } else if (block.__typename === "PrismicUsPageBodyBannerText") {
          return <UsBannerText key={`key-block-${index}`} {...block.primary} />
        } else if (block.__typename === "PrismicUsPageBodyStorytelling") {
          return <UsStorytelling key={`key-block-${index}`} {...block.primary} />
        } else if (block.__typename === "PrismicUsPageBodySvgSlides") {
          return <UsSvgSlides key={`key-block-${index}`} items={block.items} {...block.primary} />
        } else if (block.__typename === "PrismicUsPageBodyImageSlides") {
          return <UsImageSlides key={`key-block-${index}`} items={block.items} {...block.primary} />
        } else if (block.__typename === "PrismicUsPageBodyImageText") {
          return <UsImageText key={`key-block-${index}`} {...block.primary} />
        } else return null
      })}
    </Layout>
  )
}

export default About
