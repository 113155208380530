import React from "react"
import { Picture } from "react-responsive-picture"

import { TitleStroke } from "../../../components/_titles/_header_title_stroke"
import SvgSeemapCurve from "../../../components/svg/dealers/seemap_curve"
import Claim from "../../../components/_claim/claim"

export const UsBannerText = ({ title, title_filled, description, image }) => {
  return (
    <div className="us-banner-text">
      <Claim textHtml="!Agua caliente para todos!" />
      <div className="us-banner-text__bg">
        <Picture
          className="us-banner-text__img"
          sources={[
            {
              srcSet: image.xs.url,
              media: "(max-width: 767px)",
            },
            {
              srcSet: image.sm.url,
              media: "(max-width: 1023px)",
            },
            {
              srcSet: image.md.url,
              media: "(max-width: 1280px)",
            },
            {
              srcSet: image.lg.url,
              media: "(max-width: 1366px)",
            },
            {
              srcSet: image.url,
              media: "(min-width: 1367px)",
            },
          ]}
        />
        <SvgSeemapCurve />
      </div>
      <div className="us-banner-text__header">
        <TitleStroke textStroke={title.text} text={title_filled.text} />
        <p className="us-banner-text__description" dangerouslySetInnerHTML={{ __html: description.html }} />
      </div>
    </div>
  )
}
