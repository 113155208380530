import React from "react"

export const UsBlockHeader = ({ number, title, description }) => {
  const num = Number(number < 10) ? `0${number}` : number
  
  return (
    <div className="us-block-header">
      <div className="us-block-header__left">
        <span className="us-block-header__number">{num}</span>
      </div>
      <div className="us-block-header__right">
        <strong className="us-block-header__title">{title.text}</strong>
        <div className="us-block-header__description" dangerouslySetInnerHTML={{ __html: description.html }} />
      </div>
    </div>
  )
}
