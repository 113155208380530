import { useStaticQuery, graphql } from "gatsby"

export const usePrismUs = () => {
  const prismic = useStaticQuery(
    graphql`
      query {
        allPrismicUsPage {
          nodes {
            uid
            data {
              body {
                ... on PrismicUsPageBodyMetas {
                  primary {
                    title {
                      text
                    }
                    description {
                      text
                    }
                    image_share {
                      url
                    }
                  }
                }
                ... on PrismicUsPageBodyBannerText {
                  slice_type
                  primary {
                    title {
                      text
                    }
                    title_filled {
                      text
                    }
                    description {
                      html
                    }
                    image {
                      url
                      lg {
                        url
                      }
                      md {
                        url
                      }
                      sm {
                        url
                      }
                      xs {
                        url
                      }
                    }
                  }
                }
                ... on PrismicUsPageBodyStorytelling {
                  slice_type
                  primary {
                    type
                    storytelling_description {
                      html
                    }
                  }
                }
                ... on PrismicUsPageBodySvgSlides {
                  slice_type
                  primary {
                    number
                    type
                    title {
                      text
                    }
                    description {
                      html
                    }
                  }
                  items {
                    description {
                      html
                    }
                    title {
                      text
                    }
                    image {
                      url
                    }
                  }
                }
                ... on PrismicUsPageBodyImageSlides {
                  slice_type
                  primary {
                    number
                    title {
                      text
                    }
                    description {
                      html
                    }
                  }
                  items {
                    title {
                      html
                    }
                    subtitle {
                      html
                    }
                    description {
                      html
                    }
                    image {
                      url
                      md {
                        url
                      }
                      sm {
                        url
                      }
                      xs {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return prismic.allPrismicUsPage.nodes[0]
}
