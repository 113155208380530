import React from "react"
import { Picture } from "react-responsive-picture"

import BlogImageSpot from "../../../components/svg/blog/blog_image_spot"

export const UsBlockImageText = ({ orientation, subtitle, title, description, image }) => {
  return (
    <div className={`us-block-image-text ${orientation ? `is-${orientation}` : ``}`}>
      <div className={`us-block-image-text__stage`}>
        <div className={`us-block-image-text__img-container`}>
          <div className={`us-block-image-text__img-inner`}>
            <div className={`us-block-image-text__img-spot us-block-image-text__img-spot1`}>
              <BlogImageSpot />
            </div>
            <Picture
              sources={[
                {
                  srcSet: image.xs.url,
                  media: "(max-width: 767px)",
                },
                {
                  srcSet: image.sm.url,
                  media: "(max-width: 1023px)",
                },
                {
                  srcSet: image.md.url,
                  media: "(max-width: 1280px)",
                },
                {
                  srcSet: image.url,
                  media: "(min-width: 1281px)",
                },
              ]}
            />
            <div className={`us-block-image-text__img-spot us-block-image-text__img-spot2`}>
              <BlogImageSpot />
            </div>
          </div>
        </div>
        <div className={`us-block-image-text__info-container`}>
          <div className="us-block-image-text__info-inner">
            <span className={`us-block-image-text__subtitle`} dangerouslySetInnerHTML={{ __html: subtitle.html }} />
            <span className={`us-block-image-text__title`} dangerouslySetInnerHTML={{ __html: title.html }} />
            <span className={`us-block-image-text__content`} dangerouslySetInnerHTML={{ __html: description.html }} />
          </div>
        </div>
      </div>
    </div>
  )
}
